.body{
    background-color: rgb(252, 247, 247);
    padding: 0%;
    position: relative;
    margin-left: 0%
    
};
.s3-image{
    object-fit: fill;
}
.frame-img{right: 0%;
    position: absolute;
    height: 30%;
    width: 48%;
    padding-right: 0%;
    
}
.section-1{
    height: 100vh;
    width: 100;
    padding-right: 0%;
}
.coverFrm{
    position: absolute;
    width: 80%;
    right: 10%;
    left:5%;
    
    
    
}
.modelFrm{
    float:right;
    height: 40%;
    width: 50%;
   
}

.frmText{
    color: rgb(20, 20, 50);
    font-weight: 800;
    font-size: 84px;
    font-family:Georgia, 'Times New Roman', Times, serif;
}
.txtHeading{
    color: rgb(20, 20, 50);
    font-size: xx-large;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 800;
}
.txtSmallHeading{
    color: rgb(97, 97, 120);
    font-size: 18px;
    font-family:sans-serif;
    font-weight: 900;
}
.section3Img{
    height: 60%;
    width: 60%;
}
.frmBottom{
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: rgb(181, 203, 242);
    border-radius: 200px 10px 10px 10px;
    height: 60vh;
    width: 80vw;
    margin-right: 5%;
    text-align: center;
}
.btmTxt{
    padding-top: 10%;
    gap: 3;
    font-size: 40px;
    font-family: sans-serif;
    font-weight: 800;
    color: rgb(97, 97, 120);;
}
.subscribeInput{
    font-size: xx-large;
    height: 10vh;
    width: 40vw;
    border-width: 0vh;

    border-radius: 20px 20px 20px 20px;
}
.subscribeBtn{
    font-size: xx-large;
    height: 10vh;
    width: 15vw;
    margin-left: 2vh;
    border-width: 0vh;
    border-radius: 20px 20px 20px 20px;
    background-color: orange;
    color: white;
}
footer{
    background-color: rgba(164, 175, 186, 0.228);
    width: 100vw;
    
    
}
footer a:link{
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 500;
    color: rgb(20, 20, 50);;
}
.appBtn{
    width: 30vw;
    margin: 1vw;

}
.rawTxt{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vh;
    font-weight: 800;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.box{

    background-color: #c1c1c1;
    box-shadow: 20px 20px 80px  #00000041,inset -60px -60px 80px #fff;
}