.navbar .navbar-nav .nav-link:hover {
    color: #000000;
  }
.navbar .navbar-nav .nav-link {
    color: #000000;
    font-size: 1.1em;
   
  }
  .navbar-brand {
      color: rgb(1, 1, 1);
  }
  .txtHeading{
    color: rgb(27, 27, 56);
    font-size: xx-large;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 800;
}
  @media only screen and (min-width: 992px) {
    .navbar {
      padding: 0;
      
    }
    .navbar .navbar-brand {
      padding: 0 0.7em;
    }
    .navbar .navbar-nav .nav-link {
      padding: 1em 0;
    }
    .navbar .navbar-nav .nav-item {
      margin: 0 3.5em;
    }
  }
  .navbar .navbar-nav .nav-item {
    position: relative;
  }
  .navbar .navbar-nav .nav-item::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #000000;
    width: 0%;
    content: "";
    height: 4px;
  }
  .navbar .navbar-nav .nav-item:hover::after {
    width: 100%;
  }
  .navbar .navbar-nav .nav-item::after {
    transition: all 0.5s;
  }

  .logoHeader{
    height: 10rem;
    width: 10rem;
    transform: scale(2);
    padding-bottom: 1rem;
  }